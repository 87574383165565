import React from 'react';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.es';

export default function AvisoLegal() {
	return (
		<LayoutEN>
			<Seo title='Terms and conditions' />
			<div
				style={{
					margin: '0 auto',
					width: '80%',
					maxWidth: '800px',
					padding: '60px 0',
				}}
			>
				<h1>TERMS AND CONDITIONS OF SERVICE</h1>
				<p>Company: KUFLOW, S.L. (hereinafter referred to as KUFLOW)</p>
				<p>CIF (Spanish Tax Identification number for companies): B02951978</p>
				<p>January 2022</p>
				<h2>1. PURPOSE AND ACCEPTANCE OF TERMS AND CONDITIONS</h2>
				<p>
					These terms and conditions (hereinafter referred to as the
					“Conditions”) regulate acquisition and management by the user
					(hereinafter referred to as the “User” or “You”) of the right to use
					the KUFLOW service (hereinafter referred to as the “Service”) through
					the website https://www.kuflow.com (hereinafter referred to as “KUFLOW
					Website”), as well as the use of the Service. Access to and use of the
					Service may be by any of the following means: iOs, Android, or App
					operating systems or by any other means. The platform will be accessed
					through an internet browser and through the Application Programming
					Interfaces(API) of the tool or any other means established by KUFLOW,
					S.L. at any time.
				</p>
				<p>
					By accesing the KUFLOW Website or using the Service, as appropriate,
					you declare that you have read and agree to be bound by these
					Conditions of use in full. If you do not agree with any of these
					Conditions or with any part of the Conditions, you must immediately
					stop access and use of the KUFLOW Website or the Service. The access
					and use of the KUFLOW Website and the Service by the User is
					conditioned on your acceptance to these Conditions without
					reservations.
				</p>
				<p>
					Unless stated otherwise in these Conditions, these may be modified by
					KUFLOW, S.L. at any time, prior notice to the User at the email
					address registered to their account. The date indicated at the
					beginning of these Conditions corresponds to the latest update. If you
					do not agree with the new Conditions, with any part or with any
					provision of the new Conditions you may stop access and use of the
					KUFLOW Website and Service before the new Conditions take effect. If
					you continue using the KUFLOW Website and the Service, once the new
					version of the Conditions takes effect, you are declaring that you
					have read and agreed to be bound by the new Conditions without
					reservations.
				</p>
				<h2>2. LEGAL CAPACITY TO AGREE WITH CONDITIONS</h2>
				<p>
					The Service is intended for legal entities or natural persons over 18
					years of age, who act in their capacity as companies or individual
					professionals. This Service is not intended for individual consumers,
					so the regulations in those areas do not apply.
				</p>
				<p>
					If you are a natural person, by agreeing with these Conditions, you
					declare you are over 18 years of age and that you are of legal
					capacity to assume and be bound to the provisions established in these
					Conditions. If you are under 18 years of age, please, do not access or
					use the Service.
				</p>
				<p>
					If you have registered and are already using the Service on behalf of
					a legal entity, company or business, you are expressly declaring that
					you are duly authorized and legitimated to (I) represent the legal
					entity, business or company, (II) accept and agree with these
					Conditions and manage the Account (as defined below) on behalf of that
					legal entity, business or company, and (III) make that legal entity,
					business or company be bound by these Conditions. In such case, for
					the purposes of these Conditions, “you” or the “User” will mean the
					corresponding legal entity, business or company.
				</p>
				<h2>3. USE OF THE SERVICE </h2>
				<p>
					The User is only authorized to access and use the Service in
					accordance with the provisions established in these Conditions. You
					hereby agree to using the Service in good faith and you must not use
					it in any way which is negligent, illegal or fraudulent. Likewise, you
					hereby agree not to conduct any actions or behaviour which damages or
					may damage the image, interests and/or rights of KUFLOW, S.L., or of
					other users or third parties, for instance but not limited to, the use
					of reverse engineering and exploitation of the service, which may
					affect other users of the platform, or violate the security
					regulations and access to confidential information and details of
					other users.
				</p>
				<p>
					The User will be responsible for making sure, before using the
					Service, that its characteristics and different functionalitiessatisfy
					their needs and that they meet the requirements and have the necessary
					equipment and software for that purpose, assuming the necessary costs
					to use the Service.
				</p>
				<p>
					The rights granted to the User under these Conditions are personal and
					may not be transferred to third parties (including branches, entities
					of the same business group or companies which are affiliated by any
					other way), totally or partially, by any means, without prior express
					written consent of KUFLOW, S.L.
				</p>
				<p>
					Commercialization, reselling or any other similar use of the Service
					is not authorized under no circumstance and will entail the immediate
					termination of the Service.
				</p>
				<p>
					Notwithstanding the information above, the User is authorized to
					facilitate access to the Service to other individuals such as
					employees, clients, collaborators or third parties (either as Licensed
					Users or Collaborators and in compliance with these Conditions) as an
					added value to their commercial relationship, either free of charge or
					in a remunerated way. In these cases, the User only shall be liable
					for the contractual relationship with those employees, clients,
					collaborators or third parties and for their compliance with these
					Conditions. KUFLOW, S.L. shall not take any kind of liability or legal
					obligation towards those employees, clients, collaborators or third
					parties.
				</p>
				<p>
					The User is solely and exclusively responsible for the aforementioned
					files and their conduct, file content or communications using the
					Service, in accordance with the indications established below or on
					the Website. Under no circumstance will KUFLOW, S.L. control, review,
					edit, guarantee or be responsible for the content published, stored or
					shared by the User through the Service.
				</p>
				<h2>4. REGISTRATION</h2>
				<p>
					In order to use the Service, the User must register in the KUFLOW
					Website and create and activate a user account (the “Account”). To do
					so, the User will have to provide the registration information
					requested by KUFLOW Website in each case. For these purposes, the User
					shall provide a valid email address, to which the user will receive
					any notices regarding Service. Once the User has provided the
					registration information, KUFLOW, S.L. will automatically send a
					validation email to the email address indicated by the User to
					validate the Account. The Account will be created once it has been
					validated by the User.
				</p>
				<p>
					The User acknowledges that the information provided through
					registration is true, complete and up to date. The User shall be
					solely responsible for updating such information. The User shall not
					impersonate someone else upon registration.
				</p>
				<p>
					Shall the information provided by the User in the registration process
					not be true, or shall KUFLOW, S.L. have any reasons to suspect that
					said information is false, incomplete or not up to date, KUFLOW, S.L.
					may suspend or cancel the Account, the use of the Service by the User
					and/or the data or files stored by the User.
				</p>
				<p>
					The User must keep their password, treat it as confidential and not
					reveal it to other persons. Likewise, the User must not publish,
					distribute or disclose their password. The User must never allow other
					users or third parties to access their Account, or allow any other
					user to use the Account. The User will immediately inform KUFLOW, S.L.
					of any breach or suspicion of security breach or unauthorized use of
					their Account.
				</p>
				<p>
					Shall KUFLOW, S.L. have any reasons to suspect that the Account is
					being used by non-authorized third parties, KUFLOW, S.L. may suspend
					the Account, the use of the Service by the User and/or the data and
					files stored by the User.
				</p>
				<p>
					The User is solely responsible for the use, configuration, management
					and administration of the Account and for any use of the Service
					through their Account or by Licensed Users and Collaborators attached
					to their Account or of any other third party to whom the User
					authorizes to use the functionalities of the Service through any
					device or location, assuming the obligation to indemnify and hold
					KUFLOW, S.L. harmless.
				</p>
				<h2>5. FILE HOSTING, DOCUMENT MANAGEMENT AND KUFLOW PROCESS CONTROL</h2>
				<p>
					KUFLOW includes a service for file hosting, document management and
					process control.
				</p>
				<p>
					All stored, edited and managed files and data will be hosted in
					servers controlled by KUFLOW, S.L.
				</p>
				<p>
					In order to enjoy all the functionalities of the Service, for
					instance, but not limited to, performing advanced and configuration
					tasks, establishing permissions or defining workflows, the user must
					access the data and files stored through the Application.
				</p>
				<p>
					The User shall make sure that they have all the authorizations which
					are appropriate in each case in order to employ brands or third-party
					names. The User guarantees to be holder of the rights required to use
					the User Content under the terms set forth in this paragraph and shall
					indemnify and hold KUFLOW, S.L. harmless against any claim by third
					parties for the use of such User Content, being liable for any damage
					or prejudice (including legal costs) which may arise from such claim
				</p>
				<h2>6. CONTRACTED SERVICES</h2>
				<p>
					In order to use and access the Service, the User must contract a
					license (hereinafter referred to as the “License”).
				</p>
				<p>
					Once the user has contracted a Plan, the User will store, edit and
					manage their files and data and shall establish the parameters, which,
					in turn, will determine the price.
				</p>
				<p>
					There are different subscription plans based on the capacity of use of
					the platform, both regarding functionalities and intensity of use.
				</p>
				<p>
					KUFLOW, S.L. may suspend or eliminate the Account if KUFLOW, S.L.
					considers that you, or the Licensed Users or Collaborators attached to
					the Account, do not comply with the terms set forth in these
					Conditions.
				</p>
				<h2>7. PRICING</h2>
				<p>
					In order to use the Service subject of a License Plan, the User shall
					pay a fee calculated according to the monthly application and the Plan
					and the Parameters of their use of the Platform.
				</p>
				<p>
					The User may check the applicable fee for each Plan according to the
					different Parameters on the KUFLOW Website.
				</p>
				<p>
					The Fee covers the use of the software subject of the Service, the
					hosting and the maintenance service as well as additional
					functionalities, according to the Plan and Parameters contracted under
					the terms set forth in these Conditions.
				</p>
				<p>
					Monthly fees shall be paid at the end of the month. The Licenses will
					be renewed automatically and successively at the end of each month.
				</p>
				<p>
					The prices shown for each Plan on the KUFLOW Website are in euros and
					they do not include taxes, unless indicated otherwise.
				</p>
				<p>
					KUFLOW, S.L. reserves the capacity to modify the fares applicable for
					the Service (which will imply a change of the corresponding Fees) with
					notice of at least three (3) months prior to the date when the new
					fares take effect.
				</p>
				<p>
					Such changes will be notified to the User by email and it will be
					understood that the User agrees with them if the User continues using
					the Service after the changes take effect.
				</p>
				<h2>8. PAYMENT AND INVOICING</h2>
				<p>
					In order to contract a License, the User must follow the instructions
					on the KUFLOW Website, which will entail to provide the details of a
					credit or debit card with which the payment of the Fees will be made
					and which will be linked to the Account.
				</p>
				<p>
					The payment of the Fees will be made through a payment platform. The
					use of the Payment Platform will be subject to the terms, conditions
					and privacy policy of said platform, which shall be accepted by the
					User. The credit or debit card details of the User will be
					automatically stored by the payment platform, which will be
					responsible for their storage and custody.
				</p>
				<p>
					Under no circumstance will KUFLOW, S.L. have direct access to the
					credit or debit card details as entered by the User in the Payment
					Platform. KUFLOW, S.L. is not responsible for the functioning of the
					Payment Platform.
				</p>
				<p>
					When contracting the Plan chosen, you authorize KUFLOW, S.L. to make
					charges through the Payment Platform. For subsequent payments, the
					Fees will be charged at the end of the month, in accordance with the
					terms provided in these Conditions.
				</p>
				<p>
					Since this Service is not a service for individual consumers, no right
					of withdrawal nor the warranties set forth in consumer and user
					regulations apply in this case.
				</p>
				<p>
					The License will not be active until payment has been received or has
					been authorized by the corresponding bank.
				</p>
				<p>
					Upon each renewal, the appropriate Fee will be charged through the
					payment method linked to the Account at each time.
				</p>
				<p>
					In the event of non-payment of any Fee, the User will not be able to
					access the stored files or use the functionalities of the Service.
				</p>
				<p>
					The User shall include or modify their invoicing details, as well as
					the corresponding tax information through their Account. The User is
					solely responsible for the accuracy of the data provided for the
					calculation of the applicable tax rate, as well as the type of invoice
					to be issued.
				</p>
				<p>
					Shall the User be an intra-EU operator, they may request validation of
					their details in order for the correct tax rate to be applied. The
					User is hereby obliged to indemnify and hold KUFLOW, S.L. harmless
					with regard to any damages, costs, prejudice, claims, lawsuits and
					legal actions, which may be incurred by KUFLOW, S.L. as a consequence
					or in connection with any incompliance of all the agreed in this
					paragraph.
				</p>
				<h2>9. RESTRICTIONS</h2>
				<p>
					Regarding the use of the Service, of the KUFLOW Website or the Means
					of Access, you may not:
				</p>
				<p>
					Perform any reverse engineering actions, decompile, decode or hack the
					technology used by KUFLOW, S.L. to provide this Service.
				</p>
				<p>
					Impersonate another individual or entity, or falsely declare or
					manipulate the association or affiliation to a person or entity.
				</p>
				<p>
					Interfere, interrupt, or violate the safety of the Service, of the
					KUFLOW Website or of the Means of Access, or of the servers or
					networks used by KUFLOW, S.L. to provide the Service or operate the
					KUFLOW Website or the Means of Access.
				</p>
				<p>
					Conduct any actions that may damage, make unavailable, overload (as
					established by KUFLOW, S.L. at their sole discretion), or deteriorate
					or impede the normal use of the Service, of the KUFLOW Website or of
					the Means of Access, which may affect their safety, impede or
					interfere with them.
				</p>
				<p>
					Interfere or attempt to interfere in the proper functioning of the
					Service, the KUFLOW Website or the Means of Access.
				</p>
				<p>
					Access the Service through any program, application or tool different
					to those provided by KUFLOW, S.L. (excluding those created according
					to the API conditions), or intended for any other purpose different to
					the normal use of the Service, such as malicious attempts to gain
					control or rejection of the Service, performance tests or comparisons.
					This restriction applies to the KUFLOW Website and the Means of
					Access, as well as to any other electronic activity which has KUFLOW,
					S.L. infrastructure as final destination (its servers and public IP
					addresses), which is contrary to the normal operations in accordance
					with the provisions set forth in these Conditions or which
					unreasonably damages or may damage KUFLOW, S.L. interests, without
					prior express and written authorization from KUFLOW, S.L
				</p>
				<h2>10. TERMINATION OF SERVICE</h2>
				<p>
					The User acknowledges that KUFLOW, S.L. is entitled to terminate the
					Service, and, therefore, to cancel access and use of the Service by
					the User (including access to the KUFLOW Website or the Means of
					Access and to the stored data and files, even by any Licensed User or
					Collaborator attached to the Account) if there is a breach or KUFLOW,
					S.L. suspects there is a breach of the terms set forth in these
					Conditions, at any time, with or without prior notice, effective
					immediately.
				</p>
				<p>
					Especially, KUFLOW, S.L. will terminate the Service immediately if the
					user uses it for fraudulent or illegal purposes, or performs unlawful
					actions, such as contracting our hosting services for their disclosure
					on an illegal website.
				</p>
				<p>
					Termination of Service will imply the termination of the contractual
					relationship between both parties, and will imply that the User will
					not have access to the files stored, or the rest of functionalities of
					the Service.
				</p>
				<p>
					The User may terminate the Service at any time, following the
					instructions provided to such extent on the KUFLOW Website.
				</p>
				<p>
					All of the above is without prejudice to the obligations of
					conservation of documents which may apply according to the applicable
					regulations. All the provisions set forth in the present Conditions
					which, according to their nature should survive the termination of the
					contractual relationship between the User and KUFLOW, S.L., will
					remain in force after termination of the contractual relationship,
					including, but not limited to, the provisions regarding intellectual
					property, warranty disclaimers, indemnification and liability
					limitations.
				</p>
				<h2>11. INDUSTRIAL AND INTELLECTUAL PROPERTY RIGHTS</h2>
				<p>
					All industrial and intellectual property rights over the Service, the
					KUFLOW Website or the Means of Access, their design and the source
					code, including, but not limited to, logos, trademarks, distinctive
					signs, graphic design or software belong to and are owned completely
					and exclusively by KUFLOW, S.L. or its licensed partners, and are
					protected by international industrial and intellectual property
					regulations.
				</p>
				<p>
					The User is only authorized to access and use the Service, the KUFLOW
					Website or the Means of Access, as provided by KUFLOW, S.L. and as
					permitted by these Conditions. Unless specifically authorized
					otherwise, the User may not reproduce, transform, distribute, carry
					out any derivative work, make available, communicate to third parties,
					extract and/or use the Service, the KUFLOW Website or the Means of
					Access.
				</p>
				<p>
					Upon contracting the Service, KUFLOW, S.L. grants the User personal,
					limited, revokable, non-exclusive and untransferable authorization to
					use the Service (including the software subject of the Service), in
					accordance with the terms set forth in these Conditions.
				</p>
				<p>
					The User will be sole owner of the files stored, edited or managed
					through the Service. KUFLOW, S.L. shall have no right over such files,
					except the rights required to provide the Service. Consequently, the
					User grants KUFLOW, S.L., who hereby accepts, a non-exclusive, free of
					charge and universal license of use to access the files stored, edited
					or managed by the User when the provision of the Service allows it,
					including, when necessary, compliance with the applicable regulations,
					the application of these Conditions, detection and prevention of fraud
					and technical or security issues, or the protection of KUFLOW, S.L.
					rights or the rights of others or of third parties.
				</p>
				<p>
					The User hereby guarantees that they hold all the necessary rights
					over the files for their storage, editing and management through the
					Service and for the granting of the previous license in favour of
					KUFLOW, S.L. and shall indemnify and hold KUFLOW, S.L. harmless
					against any third-party claims for the use of such files, assuming any
					damages or prejudice (including legal costs) which may arise from
					those claims.
				</p>
				<p>
					KUFLOW, S.L. does not allow to perform any activities which infringe
					industrial or property rights through the use of the Service. KUFLOW,
					S.L. will eliminate any files stored, edited or managed if duly
					notified that such files infringe the industrial or intellectual
					property rights of third parties. In such cases, KUFLOW, S.L. reserves
					the right to remove any files without prior notice.
				</p>
				<h2>12. PROTECTION OF PERSONAL DATA</h2>
				<p>
					In the event that the provision of the Service requires the processing
					of personal data which are responsibility of the User, on the side of
					KUFLOW, S.L., KUFLOW, S.L. will act as the part in charge of the
					processing in accordance with the provisions established in the
					Regulation (EU) 2016/679 of the European Parliament and the Council of
					27 April 2016, on the protection of natural persons with regard to the
					processing of personal data and on the free movement of such data, and
					repealing Directive 95/46/EC (hereinafter referred to as “GDPR”).
				</p>
				<p>
					Such access to personal data does not have the consideration of a
					communication or assignment of data, it only implies the access to
					personal data by the User to provide the Service.
				</p>
				<p>
					The subject-matter, duration and nature of the processing of personal
					data are those set forth for this Service under these Conditions and
					the purpose for the processing of personal data is the provision of
					the Service.
				</p>
				<p>
					In accordance with the provisions established in the GDPR, KUFLOW,
					S.L. hereby agrees and undertakes to:
				</p>
				<p>
					Use such data in accordance with the documented instructions of the
					User, in compliance with the provisions of article 28.3.a of the GDPR.
					In the event that, in KUFLOW, S.L.’s opinion, the instructions of the
					User are contrary to the regulation on personal data protection,
					KUFLOW, S.L. will notify the user immediately.
				</p>
				<p>
					Process personal data in accordance with the security criteria set
					forth in the regulation on personal data protection and, in
					particular, article 32 of the GDPR.
				</p>
				<p>
					Assist the User to guarantee compliance with the obligations
					established in articles 32 to 36 of the GDPR, according to the nature
					of the processing and information made available to KUFLOW, S.L.
				</p>
				<p>
					Guarantee that the persons authorized to process personal data have
					committed to respecting confidentiality or are subject to a statutory
					obligation of confidentiality.
				</p>
				<p>
					Inform the User, immediately and without delay, of any breach of
					security which may affect their personal data and which are subject of
					communication as established in articles 33 and 34 of the DGPR or in
					any other applicable regulation.
				</p>
				<p>
					Collaborate with the User in a reasonable way in order to carry out
					impact evaluations with regard to personal data protection and
					previous consultations regarding the competent authorities,
					considering the nature of the processing of data and the information
					provided.
				</p>
				<p>
					Assist the User, considering the nature of the processing of data, by
					taking appropriate technical and organizational measures, whenever
					possible, so that the User is able to comply with their obligation to
					respond to the request to exercise rights by interested parties.
				</p>
				<p>
					Provide the user with the necessary information to demonstrate the
					accomplishment of the obligations set forth in this provision, always
					in accordance with reasonable criteria.
				</p>
				<p>
					Not hire any processor of data which requires access to personal data
					without prior written consent of the User, except in case of hosting
					service providers, which are hereby expressly authorized by the User.
				</p>
				<p>
					Erase all personal data we have accessed, and erase all existing
					copies, once the Service has been accomplished, unless it is required
					to keep the personal data by virtue of the regulation currently in
					force.
				</p>
				<p>
					The User represents and warrants to KUFLOW, S.L. that they comply and
					have complied in a timely, complete and precise way, regarding the
					processing of personal data commissioned to KUFLOW, S.L., with all
					formal and material obligations derived from the regulations on
					personal data protection, hereby undertaking to indemnify and hold
					KUFLOW, S.L. harmless regarding any damages, costs, prejudice, claims,
					lawsuits and legal actions incurred or which may be incurred by
					KUFLOW, S.L. as a consequence of or in connection with a breach of the
					above.
				</p>
				<h2>13. CONFIDENTIALITY</h2>
				<p>
					Both the data and documents provided by the User, as well as the files
					stored shall be strictly confidential, and will therefore be used only
					to provide the Service, and will not be disclosed to third parties
					other than employees or subcontractors of KUFLOW, S.L. or third
					parties designated by the User.
				</p>
				<p>
					In general, the employees or subcontractors of KUFLOW, S.L. will not
					have access to such files. KUFLOW, S.L. employees may access the
					metadata of the files whenever there is a duly justified reason, but
					will not have access to the content of the files.
				</p>
				<p>
					Nevertheless, in those exceptional cases where the applicable
					regulation requires so, a certain number of KUFLOW, S.L. employees may
					access the stored files or the data or documents provided by the User.
				</p>
				<h2>14. LIABILITY</h2>
				<p>
					The User hereby acknowledges and agrees to use the Service at their
					own risk and venture at all times, and therefore KUFLOW, S.L. shall
					not be liable for any misuse or inappropriate use of the Service or
					the breach of these Conditions.
				</p>
				<p>
					If you use the Service, you are using it at your own risk and venture
					and you are responsible for the compliance of the laws of your
					jurisdiction
				</p>
				<p>
					If the User should proceed to register in order to use and/or contract
					any functionality of the Service, the User is responsible for
					providing truthful and lawful information. If, as a result of
					registration or in order to use any functionality of the Service, the
					User is provided with a password, the User hereby agrees to make
					diligent use of it and keep the password secret to access the Service.
				</p>
				<p>
					Consequently, the User is responsible for the appropriate custody and
					confidentiality of any identifying information and/or passwords
					provided by KUFLOW, S.L. and hereby agrees not to assign their use to
					non-authorized third parties, either temporarily or permanently. The
					User is responsible for the illicit use of the Service by any
					illegitimate third party who employs a password for this purpose as a
					consequence of a non-diligent use or loss of the password by the User.
				</p>
				<p>
					By virtue of the foregoing, it is the obligation of the User to notify
					KUFLOW, S.L. immediately of any fact or circumstance which allows the
					inappropriate use of identifying information and/or passwords, such as
					theft, loss or non-authorized access to such information, in order to
					proceed with the immediate cancellation of said information. If
					KUFLOW, S.L. is not notified of these facts or circumstances, KUFLOW,
					S.L. exempt from any liability which may arise from the inappropriate
					use of identifying information or passwords by unauthorized third
					parties.
				</p>
				<p>
					Regarding the User’s stored or managed files through the Service,
					KUFLOW, S.L. acts exclusively as an intermediary service provider
					which consists in allowing Users to store their files and transfer
					them through the Service. The User who stores and transfers the files,
					except in the specific circumstances provided for in the applicable
					regulation, is solely and exclusively responsible for storing and
					managing their files or any other information, data, text, software,
					sound files, photos, graphics, videos, news or any other material.
					Under no circumstance will KUFLOW, S.L. be responsible for the stored
					files or their content.
				</p>
				<h2>15. APPLICABLE LAW AND JURISDICTION</h2>
				<p>
					These Conditions shall be governed and interpreted in accordance with
					the Laws of Spain, unless any other regulations apply due to the
					nature of the User and the applicable regulations on a mandatory
					basis.
				</p>
			</div>
		</LayoutEN>
	);
}
